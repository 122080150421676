import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Styled from './styles';
import { ReactComponent as BackIcon } from '../../../../icons/back.svg';
import { ReactComponent as Email } from '../../../../icons/email.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { requestVerificationWithEmail, verifyWithEmail } from '../../../../app/api';
import { isValidEmail } from '../../../../utils/helpers';
import Spinner from '../../../../components/common/spinner';
import { usePopup } from '../../../../providers/PopupContextProvider';
import { POPUP_TYPE } from '../../../../components/common/popup/constants';
import { getEventConfig } from './eventsConfig';

export default function Verification({ verify = false }) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const { event } = useParams();
	const eventConfig = getEventConfig(event.toLowerCase());
	const { showPopup, closePopup } = usePopup();

	const [email, setEmail] = useState(searchParams.get('email') || '');
	const hash = searchParams.get('hash') || '';
	const communityId = searchParams.get('communityId') || '';
	const [error, setError] = useState('');
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);

	async function send() {
		try {
			setLoading(true);
			const res = await requestVerificationWithEmail(email, communityId);
			if (res.requestStatus === 1) {
				showPopup({
					title: t('messages.succesfully_verified'),
					type: POPUP_TYPE.ALERT,
					lockUI: true,
					buttons: [
						{
							label: t('messages.community_created_button'),
							onClick: () => {
								navigate(`/communities/${communityId}`);
								closePopup();
							},
						},
					]
				});
			} else {
				setShowConfirmation(true);
			}
		} catch (e) {
			setError(e.message);
			return;
		} finally {
			setLoading(false);
		}
	}

	const startVerification = useCallback(async () => {
		try {
			setLoading(true);
			await verifyWithEmail(email, hash);
			showPopup({
				title: t('messages.succesfully_verified'),
				type: POPUP_TYPE.ALERT,
				lockUI: true,
				buttons: [
					{
						label: t('messages.community_created_button'),
						onClick: () => {
							navigate(`/communities/${communityId}`);
							closePopup();
						},
					},
				]
			});
		} catch (e) {
			setError(e.message);
			return;
		} finally {
			setLoading(false);
		}
	}, [closePopup, communityId, email, hash, navigate, showPopup, t]);

	function goBack() {
		setError('');
		setEmail('');
		navigate(-1);
	}

	useEffect(() => {
		if (!verify) return;
		startVerification();
	}, [startVerification, verify]);

	return (
		<Styled.Container $bg={eventConfig.background}>
			{(loading) && <Spinner />}
			<BackIcon onClick={goBack} />
			{eventConfig.logo && <Styled.Logo src={eventConfig.logo} />}
			<Styled.Headling $addMargin={!Boolean(eventConfig.logo)}>{t("auth.community_graphs_verification")} </Styled.Headling>
			{!showConfirmation ? (
				<>
					<Styled.Hint>{t("auth.purchase_ticket_enter_email", { event: eventConfig.name })}</Styled.Hint>
					<Styled.EmailInput placeholder={t("common.email")} Icon={Email} value={email} onChange={e => setEmail(e.target.value)} />
					<Styled.ErrorText $hidden={!Boolean(error)}>{error}</Styled.ErrorText>
					<Styled.Button onClick={send} disabled={!isValidEmail(email)}>{t('common.continue')}</Styled.Button>
					<Styled.LearnMore href="https://www.maitri.network/communitygraphs" target="_blank">
						{t("common.learn_more")}
					</Styled.LearnMore>
				</>
			) : (
				<Styled.Confirmation
					$firstParagraphColor={eventConfig.confirmationColor1}
					$lastParagraphColor={eventConfig.confirmationColor2}
				>
					<p><Trans i18nKey="auth.signup_confirmation.text1" components={{ br: <br /> }} /></p>
					<p><Trans i18nKey="auth.signup_confirmation.text2" components={{ br: <br /> }} /></p>
					<button onClick={send}>{t("auth.resend_email")}</button>
				</Styled.Confirmation>
			)}
			{eventConfig.decoration && (<Styled.Decoration src={eventConfig.decoration} />)}
		</Styled.Container>
	);
}

