import { EVENTS, EVENT_NAMES } from "../../../communities/constants";
import alephLogo from '../../../../icons/aleph.svg';
import ethSafariLogo from '../../../../icons/ethsafari.svg';
import ethSafariUnicornPng from '../../../../images/ethsafari-unicorn.png';
import { theme } from "../../../../app/theme";

export const eventConfig = {
	[EVENTS.ALEPH.toLowerCase()]: {
		logo: alephLogo,
		background: theme.colors.cyan,
		confirmationColor2: theme.colors.white,
	},
	[EVENTS.ETH_SAFARI.toLowerCase()]: {
		logo: ethSafariLogo,
		background: 'linear-gradient( to bottom, #FF59CA 0%, #E75FD8 25%, #BE64E2 43%, #8B6DF5 77%, #4B71FF 100%);',
		confirmationColor2: theme.colors.white,
		decoration: ethSafariUnicornPng,
	},
	'default': {
		background: 'linear-gradient(135deg, rgba(85,2,32,1) 0%, rgba(23,16,18,1) 25%, rgba(23,16,18,1) 25%, rgba(17,17,17,1) 100%)',
	}
};

export function getEventConfig(event) {
	let config = eventConfig[event] || eventConfig.default;
	return {
		...config,
		name: EVENT_NAMES[event.toLowerCase()] || event,
	};
}

