import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    h1 {
        font-size: 24px;
        color: white; 
    }

    label {
        color: white;
    }

    select {
        padding: 5px;
        font-size: 16px;
    }
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1000px; /* Limits the table to a maximum width */
    padding: 20px;
    text-align: center;

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }
	

    td select, td input {
        width: 100%; /* Make dropdowns and inputs take up full cell width */
        padding: 8px;
        box-sizing: border-box;
    }

    button {
        padding: 5px 10px;
        font-size: 16px;
        background-color: #555;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: #666;
        }
    }
`;

export const CalculateSection = styled.div`
    margin-top: 20px;
    text-align: center;

    button {
        padding: 10px 20px;
		margin-bottom: 10px;
        font-size: 16px;
        background-color: #555;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
            background-color: #666;
        }
    }

    p {
        margin-top: 10px;
        font-size: 18px;
        color: white;
    }
	
	#results {
		border: 1px solid #ddd;
		margin: 20px auto;
		padding: 20px;
    }
`;

