import * as Styled from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommunitiesLoading, selectSelectedEvent, setSelectedEvent } from '../../communitiesSlice';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../icons/arrow-up.svg';
import Spinner from '../../../../components/common/spinner';
import { getJoinedTopLevelCommunities } from '../../../../app/api';

const allOptions = [
	{ value: null, label: 'All events' },
]

export default function SelectEvent({ className }) {
	const dispatch = useDispatch();
	const communitiesLoading = useSelector(selectCommunitiesLoading);
	const selectedEvent = useSelector(selectSelectedEvent);
	const [showDropdown, setShowDropdown] = useState(false);
	const [options, setOptions] = useState([allOptions[0]]);

	function selectEvent(value) {
		setShowDropdown(false);
		if (value === selectedOption.value) return;
		dispatch(setSelectedEvent(value))
	}

	//hide dropdown on outside click
	const handleClickOutside = useCallback(event => {
		if (showDropdown && !event.target.closest(`${Styled.Container}`)) {
			setShowDropdown(false);
		}
	}, [showDropdown]);

	const updateOptions = useCallback(async () => {
		const joinedCommunities = await getJoinedTopLevelCommunities();
		const otherOptions = joinedCommunities.map(community => {
			return { value: community.id, label: community.name };
		});
		setOptions([
			allOptions[0],
			...otherOptions
		]);
	}, []);

	const selectedOption = options.find(option => option.value === selectedEvent) || options[0];

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [handleClickOutside]);

	useEffect(() => {
		updateOptions();
	}, [updateOptions]);

	return (
		<Styled.Container className={className}>
			{communitiesLoading && <Spinner />}
			<Styled.Content
				$showDropdown={showDropdown}
				$eventSelected={selectedOption !== options[0]}
				onClick={() => showDropdown ? setShowDropdown(false) : setShowDropdown(true)}
			>
				{selectedOption.value ? selectedOption.label : 'Choose event...'}
				<ArrowDown />
				<ArrowUp />
			</Styled.Content>
			{showDropdown &&
				<Styled.Options>
					{options.map((option, index) => (
						<Styled.Option key={index} onClick={() => selectEvent(option.value)}>
							{option.label}
						</Styled.Option>
					))}
				</Styled.Options>
			}
		</Styled.Container>
	);
}

