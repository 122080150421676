import { useRouteError } from "react-router-dom";
import { styled } from "styled-components";

export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<Container id="error-page">
			<h1>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p>
				<i>{error.statusText || error.message}</i>
			</p>
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	height: 100dvh;
	background: rgb(85,2,32);
	background: linear-gradient(135deg, rgba(85,2,32,1) 0%, rgba(23,16,18,1) 25%, rgba(23,16,18,1) 25%, rgba(17,17,17,1) 100%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	
	h1 {
		font-size: 2rem;
		font-weight: 700;
		color: rgba(255, 255, 255, 0.8);
	}

	p {
		color: rgba(255, 255, 255, 0.8);
		text-align: center;
	}

	i {
		font-size: 1rem;
		font-weight: 400;
		margin: 0.5rem 0;
		text-transform: italic;
		color: #FF4C40;
	}
`;

