import {
	RouterProvider,
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import Root from "./root";
import Communities from "../features/communities/communities";
import Community from "../features/communities/community/community";
import { theme } from "./theme";
import Users from "../features/users/users";
import Members from "../features/communities/members/members";
import { PopupContextProvider } from "../providers/PopupContextProvider";
import Polls from "../features/communities/polls/polls";
import Poll from "../features/communities/polls/poll";
import Auth from "../features/user/auth/auth";
import Popup from "../features/user/auth/popup";
import Notifications from "../features/notifications/notifications";
import Profile from "../features/user/profile";
import CreateOrEditCommunity from "../features/communities/createOrEdit";
import User from "../features/users/user";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import Signup from "../features/user/auth/signup";
import ResetPassword from "../features/user/auth/resetPassword";
import Login from "../features/user/auth/login";
import RequestResetPassword from "../features/user/auth/requestResetPassword";
import Verification from "../features/user/auth/verification";
import Reputation from "../features/reputation/reputation";
import ErrorPage from "./errorPage";

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.browserProfilingIntegration(),
		Sentry.httpClientIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions

	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is relative to tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// results in 25% of transactions being profiled (0.5*0.5=0.25)
	environment: process.env.REACT_APP_CURRENT_ENV,
	// Session Replay
	replaysSessionSampleRate: process.env.REACT_APP_CURRENT_ENV === 'PRODUCTION' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	sendDefaultPii: true, // This option is required for capturing headers and cookies.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
	createBrowserRouter,
);

const router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />, 
		children: [
			{
				path: "login",
				element: <Auth />,
			},
			{
				path: "login/:event",
				element: <Auth />,
			},
			{
				path: "login/reset-password",
				element: <RequestResetPassword />,
			},
			{
				path: "signup",
				element: <Signup />,
			},
			{
				path: "signup/reset-password",
				element: <ResetPassword />,
			},
			{
				path: "signup/set-password",
				element: <ResetPassword onBoarding={true} />,
			},
			{
				path: "verify/:event",
				element: <Verification verification/>,
			},
			{
				path: "verify/:event/check",
				element: <Verification verify />,
			},
			{
				path: "login/popup",
				element: <Popup />,
			},
			{
				path: "communities",
				element: <Communities />,
			},
			{
				path: "communities/:id",
				element: <Community />,

			},
			{
				path: "communities/:id/members",
				element: <Members />,
			},
			{
				path: "communities/:id/polls",
				element: <Polls />,
			},
			{
				path: "communities/create",
				element: <CreateOrEditCommunity />
			},
			{
				path: "communities/:id/edit",
				element: <CreateOrEditCommunity />
			},
			{
				path: "polls/:pollId",
				element: <Poll />,
			},
			{
				path: "users",
				element: <Users />,
			},
			{
				path: "users/:id",
				element: <User />,
			},
			{
				path: "notifications",
				element: <Notifications />,
			},
			{
				path: "profile",
				element: <Profile />,
			},
			{
				path: "reputation",
				element: <Reputation />,
			}
		],
	},
]);

function App() {
	return (
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
			<ThemeProvider theme={theme}>
				<PopupContextProvider>
					<RouterProvider router={router} />
				</PopupContextProvider>
			</ThemeProvider>
		</Sentry.ErrorBoundary>
	);
}

export default App;
