import { styled } from "styled-components";

export const Container = styled.div`
	position: relative;
`;

export const Content = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.colors.purplePrimary};
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};
	border-radius: 100px;
	color: ${({ theme, $eventSelected }) => $eventSelected  ? theme.colors.white : theme.colors.white50};
	cursor: pointer;

	svg {
		width: ${({ theme }) => theme.spacing[5.5]};
		height: ${({ theme }) => theme.spacing[5.5]};
		position: absolute;
		top: 50%;
		right: ${({ theme }) => theme.spacing[3]};
		transform: translateY(-50%);
	}

	svg:first-child {
		visibility: ${({ $showDropdown }) => $showDropdown ? 'hidden' : 'visible'};
	}
	svg:last-child {
		visibility: ${({ $showDropdown }) => $showDropdown ? 'visible' : 'hidden'};
	}
`;

export const Options = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 1;
	top: calc(100% + ${({ theme }) => theme.spacing[1]});
	left: 0;
	width: 100%;
	gap: ${({ theme }) => theme.spacing[1]};
`;

export const Option = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};
	border-radius: 100px;
	color: ${({ theme }) => theme.colors.black};
	cursor: pointer;
`;

