import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './attestations.styles';
import { usePopup } from '../../../providers/PopupContextProvider';
import { storeAttestations } from '../../../app/api';
import { updateAttestations, updateMembershipLevels } from '../communitiesSlice';
import { useDispatch } from 'react-redux';
import Spinner from '../../../components/common/spinner';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow-down.svg';
import AttestationsCanvasManager from "./canvas/objects/AttestationsCanvasManager";
import * as Sentry from "@sentry/react";

//TODO: canvas memory management when going back

export default function Attestations({ community, resetAttestations, addAttestedMemberId, removeAttestedMemberId, dragState, onClose, setAttestationsRef }) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation();
	const { showPopup, closePopup } = usePopup();

	const [loading, setLoading] = useState(false);
	const [redraw, setRedraw] = useState(false);
	const [areAttestationChanges, setAreAttestationChanges] = useState(false);
	const [isCanvasPanable, setIsCanvasPanable] = useState(false);

	const canvasRef = useRef();
	const canvasManagerRef = useRef();

	const onCancelClick = () => {
		setRedraw(true);
		setAreAttestationChanges(false);
		closePopup();
		resetAttestations();
	}

	useEffect(() => {
		if (setAttestationsRef) {
			setAttestationsRef({ onCancelClick });
		}
		return () => {
			if (setAttestationsRef) {
				setAttestationsRef(null);
			}
		};
	}, [setAttestationsRef, onCancelClick]);

	useEffect(() => {
		if (!canvasManagerRef.current) {
			canvasManagerRef.current = new AttestationsCanvasManager({
				addAttestedMemberId,
				removeAttestedMemberId,
				members: community.members,
				canvasElement: canvasRef.current,
				theme,
				onAttestationUpdate,
				dragState,
				setIsCanvasPanable
			});
		}

	}, []);

	const store = useCallback(async (newAttestations) => {
		try {
			setLoading(true);
			if (newAttestations.some(attestation => attestation.score < 0)) {
				const error = new Error('attestation score cannot be negative');
				console.error(error, newAttestations);
				Sentry.captureException(error, {
					extra: {
						attestations: newAttestations,
					},
				});
			}
			let data = await storeAttestations(newAttestations);
			dispatch(updateAttestations({ communityId: community.id, data: newAttestations }));
			if (typeof data === 'object') {
				dispatch(updateMembershipLevels({ communityId: community.id, data }));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [community.id, dispatch]);

	const onAttestationUpdate = useCallback((newAttestations) => {
		setAreAttestationChanges(true);
		showPopup({
			title: '',
			buttons: [
				{
					label: t('messages.attestation_cancel_button'),
					secondary: true,
					onClick: () => { onCancelClick(); }
				},
				{
					label: t('common.save'),
					onClick: () => {
						store(newAttestations);
						setAreAttestationChanges(false);
						closePopup();
						canvasManagerRef.current.onSave(newAttestations);
					},
				},
			]
		});
	}, [closePopup, showPopup, store, t]);

	useEffect(() => {
		if (redraw) {
			canvasManagerRef.current.updateMembers(community.members);
			setRedraw(false);
		}
	}, [community.members, theme, onAttestationUpdate, redraw, dragState]);

	const onCloseAttestations = useCallback(() => {
		canvasManagerRef.current.dispose();
		onClose();
	}, [onClose]);


	return (
		<Styled.Container>
			<h1>{t('common.attestations')}</h1>
			<canvas id="attestationsCanvas" className={isCanvasPanable ? "pan-cursor" : ""} ref={canvasRef}></canvas>
			{!areAttestationChanges && <ArrowDownIcon onClick={onCloseAttestations}/>}
			{loading && <Spinner/>}
		</Styled.Container>
	);
}
