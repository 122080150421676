import React, { useState } from 'react';
import * as Styled from './styles';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { isValidCryptoAddress, splitTextWithDots } from '../../../utils/helpers';

const options = [
	{ value: null, label: 'Blockchain' },
	{ value: 'ETH', label: 'Ethereum' },
	{ value: 'SOL', label: 'Solana' },
];

export default function CryptoAddresses({ addresses = [{ network: null, address: '' }], onChange }) {
	const { t } = useTranslation();
	const [invalidAddresses, setInvalidAddresses] = useState([]);
	const [focusedInputIndex, setFocusedInputIndex] = useState(-1);

	function updateAddress(index, key, value) {
		const nextAddresses = addresses.map(address => ({ ...address }));
		nextAddresses[index][key] = value;
		if (key === 'address') {
			setInvalidAddresses(invalidAddresses.filter(address => address !== nextAddresses[index].network));
		}
		triggerChange(nextAddresses);
	}

	function addAddress() {
		const nextAddresses = [...addresses, { network: null, address: '' }];
		triggerChange(nextAddresses);
	}

	function removeAddress(index) {
		const nextAddresses = [...addresses];
		nextAddresses.splice(index, 1);
		triggerChange(nextAddresses);
	}

	function triggerChange(nextAddresses) {
		onChange(nextAddresses);
	}

	function checkAddresses() {
		const invalid = [];
		addresses.forEach(address => {
			if (!address.network) return;
			if (!isValidCryptoAddress(address.network, address.address)) {
				invalid.push(address.network);
			}
		});
		setInvalidAddresses(invalid);
	}

	return (
		<Styled.Container>
			<h5>{t('user.crypto_addresses')}</h5>
			{addresses.map((address, index) => (
				<React.Fragment key={index}>
					<Styled.Row >
						<Styled.Dropdown
							onChange={opt => updateAddress(index, 'network', opt.value)}
							options={options.filter(opt => opt.value == null || opt.value === address.network || !addresses.some(addr => addr.network === opt.value))}
							firstOptionSelectable={false}
							value={address.network}
						/>
						<Styled.AddressInput
							placeholder={t('user.address')}
							value={focusedInputIndex === index ? address.address : splitTextWithDots(address.address, 6, 4)}
							onChange={e => updateAddress(index, 'address', e.target.value)}
							disabled={!address.network}
							onFocus={() => setFocusedInputIndex(index)}
							onBlur={() => {setFocusedInputIndex(-1); checkAddresses()}}
							$isInvalid={invalidAddresses.includes(address.network)}
						/>
						<TrashIcon onClick={() => removeAddress(index)} />
					</Styled.Row>
					{invalidAddresses.includes(address.network) && <Styled.Error>{t('errors.invalid_address')}</Styled.Error>}
				</React.Fragment>
			))}
			{addresses.length < options.length - 1 && (
				<Styled.AddAddress onClick={addAddress}>
					<PlusIcon />
					<span>{t("user.add_another_address")}</span>
				</Styled.AddAddress>
			)}
		</Styled.Container>
	)
}

