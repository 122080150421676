import { useNavigate } from "react-router-dom";
import * as Styled from './reputation.styles'; // Updated styles
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { calculateReputationScores, getAllUsers } from "../../app/api";
import Spinner from "../../components/common/spinner";
import { selectUsers, setUsers } from "../users/usersSlice";
import { selectCommunities, selectSelectedEvent } from "../communities/communitiesSlice";

export default function Reputation() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const selectedEvent = useSelector(selectSelectedEvent);
	const users = useSelector(selectUsers);
	const communities = useSelector(selectCommunities);

	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [results, setResults] = useState(null);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				setLoading(true);
				const result = await getAllUsers(selectedEvent);
				dispatch(setUsers(result));
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};
		fetchUsers();
	}, [dispatch, selectedEvent]);

	const handleAddRow = () => {
		const defaultUser = users.length > 0 ? users[0] : { id: "", handle: "" };
		setRows([...rows, { nodeType: "User", id: defaultUser.id, handle: defaultUser.handle, weight: "1" }]);
	};

	const handleRemoveRow = (index) => {
		const updatedRows = rows.filter((_, i) => i !== index);
		setRows(updatedRows);
	};

	const handleChangeNodeType = (index, value) => {
		const updatedRows = [...rows];
		updatedRows[index].nodeType = value;

		if (value === "User") {
			const defaultUser = users.length > 0 ? users[0] : { id: "", handle: "" };
			updatedRows[index].id = defaultUser.id;
			updatedRows[index].handle = defaultUser.handle;
		} else if (value === "Community") {
			const defaultCommunity = communities.length > 0 ? communities[0] : { id: "", handle: "" };
			updatedRows[index].id = defaultCommunity.id;
			updatedRows[index].handle = defaultCommunity.handle;
		}

		setRows(updatedRows);
	};

	const handleChange = (index, field, value) => {
		const updatedRows = [...rows];
		updatedRows[index][field] = value;

		if (field === 'handle') {
			const list = rows[index].nodeType === "User" ? users : communities;
			const selectedItem = list.find(item => item.handle === value);
			updatedRows[index].id = selectedItem ? selectedItem.id : "";
		}

		setRows(updatedRows);
	};

	const handleWeightBlur = (index) => {
		const updatedRows = [...rows];
		const numericValue = parseFloat(updatedRows[index].weight);
		if (numericValue < 0 || numericValue > 1 || isNaN(numericValue)) {
			updatedRows[index].weight = "1";
		}
		setRows(updatedRows);
	};

	const calculate = async () => {
		const weights = rows.map(row => ({
			subjectUserOrCommunityId: row.id,
			weight: row.weight
		}));

		const payload = {
			reputationSystemId: "testReputationSystem",
			weights,
		};

		const scores = await calculateReputationScores(payload);

		const scoresJSX = Object.entries(scores).map(([id, score]) => {
			const handle = rows.find(row => row.id === id)?.handle || id;
			return <p><span  style={{color:"#00dcf4"}}>{handle}</span>: {score}</p>;
		});

		setResults(scoresJSX);
	};

	return (
		<Styled.Container>
			{loading && <Spinner />}
			<Styled.Header>
				<div>
					<h1>{t('common.reputation')}</h1>
				</div>
				<div>
					<label>{t('reputation.algorithm')}</label>
					<select>
						<option value="testReputationSystem">testReputationSystem</option>
					</select>
				</div>
			</Styled.Header>
			<Styled.Content>
				<table>
					<thead>
					<tr>
						<th>Node Type</th>
						<th>Node Handle</th>
						<th>Weight</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{rows.map((row, index) => (
						<tr key={index}>
							{/* Node Type Dropdown */}
							<td>
								<select
									value={row.nodeType}
									onChange={(e) => handleChangeNodeType(index, e.target.value)}
								>
									<option value="User">User</option>
									<option value="Community">Community</option>
								</select>
							</td>

							{/* Node Handle Dropdown */}
							<td>
								<select
									value={row.handle}
									onChange={(e) => handleChange(index, 'handle', e.target.value)}
								>
									{(row.nodeType === "User" ? users : communities).map((item) => (
										<option key={item.id} value={item.handle}>
											{item.handle}
										</option>
									))}
								</select>
							</td>

							{/* Weight Numerical Entry */}
							<td>
								<input
									type="number"
									value={row.weight}
									onChange={(e) => handleChange(index, 'weight', e.target.value)}
									onBlur={() => handleWeightBlur(index)}
									min="0"
									max="1"
									step="0.01"
								/>
							</td>

							{/* Add/Remove Row Buttons */}
							<td>
								<button type="button" onClick={() => handleRemoveRow(index)}>-</button>
							</td>
						</tr>
					))}
					</tbody>
				</table>
				<button type="button" onClick={handleAddRow}>+</button>
			</Styled.Content>
			<Styled.CalculateSection>
				<button type="button" onClick={calculate}>Calculate</button>
				<h1>Scores</h1>
				<div id="results">
					{results}
				</div>
			</Styled.CalculateSection>
		</Styled.Container>
	);
}
