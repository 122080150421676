import { POPUP_TYPE } from '../common/popup/constants';
import * as Styled from './styles';
import { ReactComponent as CloseIcon } from '../../icons/x-small.svg';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

export default function Tutorial({ className, onClose, texts = [], ...props }) {
	const [step, setStep] = useState(0);
	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => updateStep(step + 1),
		onSwipedRight: () => updateStep(step - 1),
		trackMouse: true
	});

	function updateStep(val) {
		if (val >= 0 && val < texts.length) {
			setStep(val);
		}
	}

	return (
		<Styled.PopupContainer
			className={className}
			isOpen={true}
			onClickOutside={onClose}
		>
			<Styled.Content {...swipeHandlers}>
				<CloseIcon onClick={onClose} />
				<Styled.Bars>
					{
						texts.map((_, index) => (
							<Styled.Bar
								key={index}
								onClick={() => setStep(index)}
								$complete={index <= step}
							/>
						))
					}
				</Styled.Bars>
				<p >{texts[step]}</p>
				<Styled.Arrows>
					<span onClick={() => updateStep(step - 1)}>&lt;&lt;</span>
					<span onClick={() => updateStep(step + 1)}>&gt;&gt;</span>
				</Styled.Arrows>
			</Styled.Content>
		</Styled.PopupContainer>
	);
}

