import { createSlice } from '@reduxjs/toolkit'

const initialState = {
}

export const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.id = action.payload.id;
			state.name = action.payload.name;
			state.handle = action.payload.handle;
			state.email = action.payload.email;
			state.avatar_url = action.payload.avatar_url;
			state.communities = action.payload.communities.map(communityId => ({
				id: communityId,
				managed: true
			}));
			state.is_profile_complete = action.payload.is_profile_complete;
			state.crypto_currency_addresses = action.payload.crypto_currency_addresses
		},
		setProfile: (state, action) => {
			state.name = action.payload.name;
			state.handle = action.payload.handle;
			state.avatar_url = action.payload.avatar_url;
		},
		joinCommunity: (state, action) => {
			state.communities.push({
				id: action.payload,
				managed: false,
			});
		},
		leaveCommunity: (state, action) => {
			state.communities = state.communities.filter(community => community.id !== action.payload);
		},
	},
})

export const { setUser, joinCommunity, leaveCommunity, setProfile } = user.actions;

export const selectUser = state => state.user;

export default user.reducer;
