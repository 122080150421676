
import { useDispatch, useSelector } from "react-redux";
import * as Styled from './styles';
import { useEffect, useMemo, useRef, useState } from "react";
import Spinner from "../../../components/common/spinner";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { usePopup } from "../../../providers/PopupContextProvider";
import Input from "../../../components/common/input";
import Textarea from "../../../components/common/textarea";
import { createCommunity, getJoinedTopLevelCommunities, updateCommunity } from "../../../app/api";
import communitiesSlice, { fetchAllCommunities, selectCommunities, selectCommunitiesLoading } from "../communitiesSlice";
import { POPUP_TYPE } from "../../../components/common/popup/constants";
import { joinCommunity } from "../../user/userSlice";
import { ReactComponent as TickIcon } from '../../../icons/green-tick-outline.svg';
import Switch from "../../../components/common/switch";
import { useTheme } from "styled-components";
import CropBox from "../../../components/common/cropper";
import { MAX_FILE_SIZE } from "../constants";
import { useTranslation } from "react-i18next";
import { isValidUrl } from "../../../utils/helpers";
import { GATING_MECHANISMS } from "../../user/constants";
import { DIRECTION } from "../../../components/common/select/constants";

const errorTextsByField = {
	all: 'You must fill out all of the required fields',
	fileSize: 'File size is bigger than 1MB',
};

export default function CreateCommunity() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const theme = useTheme();
	const { id: communityId } = useParams();
	const communitiesLoading = useSelector(selectCommunitiesLoading);
	const communities = useSelector(selectCommunities);

	const { showPopup, closePopup } = usePopup();
	const [showCropBox, setShowCropBox] = useState(false);
	const [data, setData] = useState({
		name: '',
		handle: '',
		tg_link: '',
		description: '',
		avatar: null,
		privacy_level: 1,
		is_permissionless: false,
		gating_mechanism: null,
		gated_by: null,
	});
	const [avatarUrl, setAvatarUrl] = useState('');
	const [cropImage, setCropImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [lastFocusedField, setLastFocusedField] = useState(null);
	const [gatingCommunities, setGatingCommunities] = useState([]);

	const inputRef = useRef(null);

	async function create() {
		if (data.avatar && data.avatar.size > MAX_FILE_SIZE) {
			setError(errorTextsByField.fileSize);
			return;
		}
		try {
			setLoading(true);
			if (communityId) {
				await updateCommunity({ ...data, id: communityId });
				onFinish(communityId);
			} else {
				let res = await createCommunity(data);
				onFinish(res.community_id);
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	}

	function onFinish(id) {
		if (!communityId) {
			dispatch(joinCommunity(id));
		}
		dispatch(fetchAllCommunities());
		showPopup({
			title: communityId ? t('messages.community_updated') : t('messages.community_created'),
			type: POPUP_TYPE.ALERT,
			lockUI: true,
			buttons: [
				{
					label: t('messages.community_created_button'),
					onClick: () => {
						navigate(`/communities/${id}`);
						closePopup();
					},
				},
			]
		});
	}

	function onUpload(event) {
		const file = event.target.files[0];
		const blob = URL.createObjectURL(file);
		setCropImage(blob);
		setShowCropBox(true);
	}

	function onCrop(blob) {
		if (blob.size > MAX_FILE_SIZE) {
			setError(errorTextsByField.fileSize);
			return;
		}
		setError(null);
		setData({ ...data, avatar: blob });
		setAvatarUrl(URL.createObjectURL(blob));
		setShowCropBox(false);
	}

	function updateData(key, value) {
		const nextData = { ...data };
		if (key === 'gated_by') {
			if (value === null) {
				nextData.gating_mechanism = null;
			} else {
				nextData.gating_mechanism = GATING_MECHANISMS.COMMUNITY;
			}
		}
		nextData[key] = value;
		setData(nextData);
	}

	const gatingOptions = useMemo(() => {
		const options = [{ label: 'Choose community (optional)', value: null }];
		gatingCommunities.forEach(community => {
			options.push({ label: community.name, value: community.id });
		});

		return options;
	}, [gatingCommunities]);

	const disableButton = useMemo(() => {
		return (
			error ||
			data.name === '' ||
			data.handle === '' ||
			data.description === '' ||
			data.avatar === null
		);
	}, [data, error])

	useEffect(function getGatingCommunities() {
		async function fetchGatingCommunities() {
			const communities = await getJoinedTopLevelCommunities();
			setGatingCommunities(communities);
		}
		fetchGatingCommunities();
	}, [dispatch]);

	useEffect(function validate() {
		if (lastFocusedField) {
			if (data[lastFocusedField] === '' && lastFocusedField !== 'tg_link') {
				setError(errorTextsByField.all);
				return;
			}
			if (lastFocusedField === 'tg_link' && data.tg_link && !isValidUrl(data.tg_link)) {
				setError('Inalid URL');
				return;
			}
			if (lastFocusedField === 'handle' && data.handle && !/^[a-z0-9-]+$/.test(data.handle)) {
				setError(t('errors.handle_restriction'));
				return;
			}
		}
		setError('');
	}, [data, lastFocusedField, t]);

	useEffect(() => {
		if (communityId) {
			const community = communities.find(c => c.id === communityId);
			if (community) {
				setData({
					name: community.name,
					handle: community.handle,
					tg_link: community.tg_link,
					description: community.description,
					privacy_level: community.privacy_level,
					is_permissionless: community.is_permissionless,
				});
				setAvatarUrl(community.avatar_url);
			}
		}
	}, [communities, communityId]);

	return (
		<Styled.Container>
			{(loading || communitiesLoading) && <Spinner />}
			{showCropBox && <CropBox src={cropImage} onCrop={onCrop} onCancel={() => setShowCropBox(false)} />}
			<Styled.Header>
				<CloseIcon onClick={() => navigate(`/communities`)} />
				<h1>{t('communities.create_community')}</h1>
			</Styled.Header>
			<Styled.Avatar onClick={() => { setLastFocusedField('avatar'); inputRef.current.click() }}>
				<img src={avatarUrl} alt="Avatar" />
				<span >{t('common.upload_image')} &nbsp;(&lt; 1{t('common.mb')})</span>
				<input type="file" ref={inputRef} onChange={onUpload} />
			</Styled.Avatar>
			<Styled.Details>
				<h2>Details</h2>
				<Input
					placeholder="Community name"
					value={data.name}
					onChange={e => updateData('name', e.target.value)}
					onBlur={() => setLastFocusedField('name')}
				/>
				<Input
					placeholder="Community handle"
					autocapitalize="off"
					value={data.handle}
					onChange={e => updateData('handle', e.target.value)}
					onBlur={() => setLastFocusedField('handle')}
				/>
				<Input
					placeholder="Telegram channel link (optional)"
					value={data.tg_link}
					onChange={e => updateData('tg_link', e.target.value)}
					onBlur={() => setLastFocusedField('tg_link')}
				/>
				<Textarea
					placeholder="Description"
					value={data.description}
					onChange={e => updateData('description', e.target.value)}
					onBlur={() => setLastFocusedField('description')}
				/>
			</Styled.Details>
			<Styled.Privacy>
				<h2>{t('communities.privacy_level')}</h2>
				<Styled.Permissionless>
					<p>{t('communities.permissionless_text')}</p>
					<Switch
						on={data.is_permissionless}
						width={theme.spacing[18]}
						onClick={() => updateData('is_permissionless', !data.is_permissionless)}
					/>
				</Styled.Permissionless>
				<p>{t('communities.publicly_available_elements')}:</p>
				<div>
					<div>
						<span>{t('common.community')}</span>
						<span>{t('common.members')}</span>
						<span>{t('common.membership_levels')}</span>
					</div>
					<Styled.PrivacyColumn $active={data.privacy_level === 1} onClick={() => updateData('privacy_level', 1)}>
						<span>{t('common.low')}</span> <TickIcon /> <TickIcon /> <TickIcon />
					</Styled.PrivacyColumn>
					<Styled.PrivacyColumn $active={data.privacy_level === 2} onClick={() => updateData('privacy_level', 2)}>
						<span>{t('common.medium')}</span> <TickIcon /> <TickIcon /> <span>-</span>
					</Styled.PrivacyColumn>
					<Styled.PrivacyColumn $active={data.privacy_level === 3} onClick={() => updateData('privacy_level', 3)}>
						<span>{t('common.high')}</span> <TickIcon /> <span>-</span> <span>-</span>
					</Styled.PrivacyColumn>
					<Styled.PrivacyColumn $active={data.privacy_level === 4} onClick={() => updateData('privacy_level', 4)}>
						<span>{t('common.max')}</span> <span>-</span> <span>-</span> <span>-</span>
					</Styled.PrivacyColumn>
				</div>
			</Styled.Privacy>
			<Styled.Gating>
				<h3>{t('common.gating')}</h3>
				<p>{t('communities.gating_description')}</p>
				<Styled.Dropdown onChange={opt => updateData('gated_by', opt.value)} options={gatingOptions} direction={DIRECTION.UP} />
			</Styled.Gating>
			<Styled.Footer isOpen>
				<div className='buttons'>
					<button onClick={create} disabled={disableButton}>{communityId ? t('common.save') : t('communities.create_community')}</button>
				</div>
				{error && <p className='error'>{error}</p>}
			</Styled.Footer>
		</Styled.Container>
	);
}

