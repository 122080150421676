import React, { useState, useEffect, useCallback } from 'react';
import * as Styled from './styles';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../icons/arrow-up.svg';
import { DIRECTION } from './constants';

export default function Select({ options, onChange, className, value, firstOptionSelectable = true, direction = DIRECTION.DOWN }) {
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedValue, setSelectedValue] = useState(value || options[0].value);

	function selectOption(option) {
		setShowDropdown(false);
		if (option.value === selectedValue) return;
		setSelectedValue(value || option.value);
		onChange(option);
	}

	//hide dropdown on outside click
	const handleClickOutside = useCallback(event => {
		if (showDropdown && !event.target.closest(`${Styled.Container}`)) {
			setShowDropdown(false);
		}
	}, [showDropdown]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [handleClickOutside]);

	return (
		<Styled.Container className={className}>
			<Styled.Content
				$showDropdown={showDropdown}
				$selected={selectedValue !== options[0].value}
				onClick={() => showDropdown ? setShowDropdown(false) : setShowDropdown(true)}
			>
				{options.find(opt => opt.value === (value || selectedValue)).label}
				<ArrowDown />
				<ArrowUp />
			</Styled.Content>
			{showDropdown &&
				<Styled.Options $direction={direction}>
					{(firstOptionSelectable ? options : options.slice(1)).map((option, index) => (
						<Styled.Option key={index} onClick={() => selectOption(option)}>
							{option.label}
						</Styled.Option>
					))}
				</Styled.Options>
			}
		</Styled.Container>
	);
}
