import { styled } from "styled-components";
import { DIRECTION } from "./constants";

function dropdownStyles(direction, theme) {
	switch (direction) {
		case DIRECTION.UP:
			return `
				bottom: calc(100% + ${theme.spacing[2]});
				left: 0;
			`;
		case DIRECTION.DOWN:
			return `
				top: calc(100% + ${theme.spacing[2]});
				left: 0;
			`;
		case DIRECTION.LEFT:
			return `
				top: 0;
				right: calc(100% + ${theme.spacing[2]});
			`;
		case DIRECTION.RIGHT:
			return `
				top: 0;
				left: calc(100% + ${theme.spacing[2]});
			`;
		default:
			return '';
	}
}

export const Container = styled.div`
	position: relative;
`;

export const Content = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.colors.white10};
	padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[3]}`};
	border-radius: ${({ theme }) => theme.spacing[1.5]};
	color: ${({ theme, $selected }) => $selected  ? theme.colors.white : theme.colors.white50};
	cursor: pointer;

	svg {
		width: ${({ theme }) => theme.spacing[5.5]};
		height: ${({ theme }) => theme.spacing[5.5]};
		position: absolute;
		top: 50%;
		right: ${({ theme }) => theme.spacing[3]};
		transform: translateY(-50%);
	}

	svg:first-child {
		visibility: ${({ $showDropdown }) => $showDropdown ? 'hidden' : 'visible'};
	}

	svg:last-child {
		visibility: ${({ $showDropdown }) => $showDropdown ? 'visible' : 'hidden'};
	}
`;

export const Options = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 1;
	${({ theme, $direction }) => dropdownStyles($direction, theme)};
	width: 100%;
	gap: ${({ theme }) => theme.spacing[2]};
`;

export const Option = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[3]}`};
	border-radius: ${({ theme }) => theme.spacing[1.5]};
	color: ${({ theme }) => theme.colors.black};
	cursor: pointer;
`;

