
import styled from 'styled-components';
import Popup from '../../../components/common/popup/popup';
import Select from '../../../components/common/select';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} 0 ${theme.spacing[8]}`};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;

	svg {
		margin-bottom: ${({ theme }) => theme.spacing[4]};
		background-color: ${({ theme }) => theme.colors.white10};	
		border-radius: ${({ theme }) => theme.spacing[3]};
		cursor: pointer;
		
		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}

	h1 {
		font-size: ${({ theme }) => theme.spacing[10]};
		line-height: 1.2;
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const Avatar = styled.div`
	margin-top: ${({ theme }) => theme.spacing[10]};
	border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

	img {
		width: ${({ theme }) => theme.spacing[40]};
		height: ${({ theme }) => theme.spacing[40]};
		border: ${({ theme }) => `${theme.spacing[1.5]} solid ${theme.colors.white10}`};
		object-fit: cover;
		vertical-align: middle;
		border-radius: 50%;
	}
	
	span {
		margin-top: ${({ theme }) => theme.spacing[4]};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
		text-decoration: underline;
	}

	input {
		display: none;
	}
`;

export const Details = styled.div`
	margin-top: ${({ theme }) => theme.spacing[11]};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[3]};

	h2 {
		margin-bottom: ${({ theme }) => theme.spacing[3]};
		font-size: ${({ theme }) => theme.spacing[7]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const Privacy = styled.div`
	margin-top: ${({ theme }) => theme.spacing[8]};
	padding-top: ${({ theme }) => theme.spacing[8]};
	border-top: 1px solid ${({ theme }) => theme.colors.white10};
	display: flex;
	flex-direction: column;

	gap: ${({ theme }) => theme.spacing[3]};

	h2 {
		font-size: ${({ theme }) => theme.spacing[7]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
	}
	
	p {
		font-size: ${({ theme }) => theme.spacing[4]};
		color: ${({ theme }) => theme.colors.white50};
	}

	span {
		text-transform: capitalize;
	}
	
	>div:last-child {
		margin-top: ${({ theme }) => theme.spacing[5]};
		display: flex;
		gap: ${({ theme }) => theme.spacing[1.5]};
		>div:first-child {	
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			color: ${({ theme }) => theme.colors.white};
			padding: ${({ theme }) => `${theme.spacing[21]} ${theme.spacing[2]} ${theme.spacing[6]} 0`};
		}
		
	}
`;

export const Permissionless = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: ${({ theme }) => theme.spacing[4]};
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const PrivacyColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[8]};
	padding: ${({ theme }) => theme.spacing[4.5]};
	border-radius: ${({ theme }) => theme.spacing[3]};
	border: ${({ theme, $active }) => $active ? `1px solid ${theme.colors.white15}` : 'none'};
	background-color: ${({ theme, $active }) => $active ? theme.colors.white15 : theme.colors.white05};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	
	svg {
		width: ${({ theme }) => theme.spacing[8]};
		height: ${({ theme }) => theme.spacing[8]};
	}
	span {
		width: ${({ theme }) => theme.spacing[8]};
		height: ${({ theme }) => theme.spacing[8]};
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;


export const Gating = styled.div`
	margin-top: ${({ theme }) => theme.spacing[8]};
	display: flex;
	flex-direction: column;
	>h3 {
		font-size: ${({ theme }) => theme.spacing[7]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
		text-transform: capitalize;
	}
	>p {
		margin-top: ${({ theme }) => theme.spacing[8]};
		font-size: ${({ theme }) => theme.spacing[4]};
		line-height: 1.5;
		color: ${({ theme }) => theme.colors.white50};
	}
`;

export const Dropdown = styled(Select)`
	margin-top: ${({ theme }) => theme.spacing[7]};
`;

export const Footer = styled(Popup)`
	margin-top: ${({ theme }) => theme.spacing[8]};
	position: static;
	height: auto;
	.content {
		position: relative;
		width: ${({ theme }) => `calc(100% + ${theme.spacing[16]})`};
		left: ${({ theme }) => `-${theme.spacing[8]}`};
		transform: none;
		gap: ${({ theme }) => theme.spacing[3]};
	}

	.error {
		max-width: 80%;
		align-self: center;
		padding: ${({ theme }) => theme.spacing[3.5]};
		background-color: ${({ theme }) => theme.colors.magenta10};
		border: 1px solid ${({ theme }) => theme.colors.magenta50};
		border-radius: ${({ theme }) => theme.spacing[1]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[3.5]};
		font-weight: 300;
		text-align: center;
	}

	button:disabled {
		opacity: 0.2;
	}
`;

