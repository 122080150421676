import styled from 'styled-components';
import Input from '../../../components/common/input';
import Select from '../../../components/common/select';

export const Container = styled.div`
	h5 {
		padding-left: ${({ theme }) => theme.spacing[5.5]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 600;
	}
	
	svg {
		width: ${({ theme }) => theme.spacing[6]};
		height: ${({ theme }) => theme.spacing[6]};
		cursor: pointer;
	}
`;

export const Row = styled.div`
	margin-top: ${({ theme }) => theme.spacing[2]};
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[4]};
	
	>div:last-of-type {
		flex-grow: 1;
	}
`;

export const Dropdown = styled(Select)`
	width: ${({ theme }) => theme.spacing[40]};
`;

export const AddAddress = styled.div`
	margin-top: ${({ theme }) => theme.spacing[6]};
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[2]};
	cursor: pointer;

	svg {
		width: ${({ theme }) => theme.spacing[6]};
		height: ${({ theme }) => theme.spacing[6]};
	}
	
	span {
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[3.5]};
	}
`;

export const AddressInput = styled(Input)`
	input {
		background-color: ${({ theme, $isInvalid }) => $isInvalid ? theme.colors.errorRed10 : theme.colors.white10};
	}
`;

export const Error = styled.p`
	margin: ${({ theme }) => `${theme.spacing[3]} 0`};
	color: ${({ theme }) => theme.colors.errorRed};
	padding-right: ${({ theme }) => theme.spacing[10]};
	text-align: right;
`;

